const baseUrl = process.env.VUE_APP_DOCADMIN_API_BASE || null
let baseRoot = process.env.VUE_APP_API_URL || null

if (!baseRoot.endsWith('/')) { 
  baseRoot = `${baseRoot}/`
}

export default {
  docAdmin: {
    baseUrl: baseUrl,
    adminv1: {
      documentItemTranslationChange: `${process.env.VUE_APP_API_URL}admin/app/documentitemtranslations/`,
      documentChange: `${process.env.VUE_APP_API_URL}admin/app/documents/`,
      personChange: `${process.env.VUE_APP_API_URL}admin/app/persons/`,
      personAdd: `${process.env.VUE_APP_API_URL}admin/app/persons/add/`
    },
    pageSize: 100,
    endpoints: {
      customDocuments: baseUrl + '/stanza/documents/',
      customDocumentItems: baseUrl + '/stanza/documentitems/',
      documentTypes: baseUrl + '/documenttypes/',
      documents: baseUrl + '/documents/',
      documentAuthors: baseUrl + '/document/authors/',
      documentTranslations: baseUrl + '/documenttranslations/',
      documentItems: baseUrl + '/documentitems/',
      documentItemTypes: baseUrl + '/documentitemtypes/',
      documentItemTranslations: baseUrl + '/documentitemtranslations/',
      documentItemGroupings: baseUrl + '/documentitem/groupings/',
      documentItemNextPrevious: baseUrl + '/documentitem/nextprevious',
      documentItemGroupingsByGroup: baseUrl + '/documentitemgroupings',
      documentItemWithChildren: baseUrl + '/documentitemwithchildren',
      deleteDocumentItemsWithChildren: baseUrl + '/documentitem/delete',
      persons: baseUrl + '/persons/',
      personTranslations: baseUrl + '/person/translations/',
      phrases: baseUrl + '/phrases/',
      phraseTranslations: baseUrl + '/phrase/translations/',
      languages: baseUrl + '/languages/',
      metadata: baseUrl + '/metadata/',
      terms: baseUrl + '/terms/',
      termTranslations: baseUrl + '/termtranslations/',
      termRelationship: baseUrl + '/termrelationships/',
      termConnections: baseUrl + '/termconnections/',
      termTypes: baseUrl + '/termtypes',
      imageManagerUpload: baseUrl + '/image/upload',
      imageManagerDelete: baseUrl + '/image/delete',
      dataStructures: baseUrl + '/datastructures/',
      connections: baseUrl + '/connections/',
      connectionsInOut: baseUrl + '/connectionsinout/',
      definitions: baseUrl + '/definitions/',
      definitionExplanations: baseUrl + '/definition/explanations/',
      definitionExplanationTranslations:
        baseUrl + '/definition/explanation/translations/',
      documentSearch: baseUrl + '/search/documents/',
      personSearch: baseUrl + '/search/persons/',
      definitionSearch: baseUrl + '/search/definitions/',
      synonyms: baseUrl + '/definition/synonyms/',
      definitionsReassign: baseUrl + '/utils/definition/reassign/',
      account: {
        register: baseUrl + '/account/register/',
        logout: baseUrl + '/account/logout/',
        token: baseUrl + '/account/token/',
        tokenRefresh: baseUrl + '/account/token/refresh/',
        tokenVerify: baseUrl + '/account/token/verify/',
        profile: baseUrl + '/account/profile/'
      },
      referenceDocuments: baseUrl + '/reference/documents',
      referenceDocumentItems: baseUrl + '/reference/documentitems',
      permissionLevels: baseUrl + '/permissionlevels/',
      documentVisibility: baseUrl + '/documentvisibility/',
      documentItemVisibility: baseUrl + '/documentitemvisibility/',
      documentPermissions: baseUrl + '/documentpermissions/',
      documentItemPermissions: baseUrl + '/documentitempermissions/',
      firebaseusers: baseUrl + '/firebaseusers/',
      groups: baseUrl + '/groups/',
      groupMembers: baseUrl + '/groupmembers/'
    },
    endpointUtils: {
      adminSettingsCouchbase: baseRoot + 'app/settings/cb',
      clearCache: baseRoot + 'app/clearcache/',
      export: {
        readingPlan: baseRoot + 'export/readingplan',
        readingPlans: baseRoot + 'export/readingplans'
        // baseRoot + 'export/readingplans',
        // baseRoot + 'export/kirtans',
        // baseRoot + 'export/kirtanpads',
        // baseRoot + 'export/verse',
        // baseRoot + 'export/verses',
        // baseRoot + 'export/raag',
        // baseRoot + 'export/raags',
        // baseRoot + 'export/stanza',
        // baseRoot + 'export/stanzas',
        // baseRoot + 'export/document',
        // baseRoot + 'export/chapters',
        // baseRoot + 'export/languages',
        // baseRoot + 'export/definition',
        // baseRoot + 'export/definitions',
        // baseRoot + 'export/phrases',
        // baseRoot + 'export/persons',
        // baseRoot + 'export/translitify',
      }
    }
  }
}
